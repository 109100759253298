.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  position: relative;
  background-color: #e28bce;
  color: #f0f8ff;
}
.DayPicker-Day--today {
  color: rgba(0, 0, 0, 0.87);
  font-weight: bold;
}
.DayPicker-Month {
  margin: 1em 0 0 0;
}
